import { SportsListService } from './_services/sports.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { NgxPaginationModule } from 'ngx-pagination';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import {
  AuthenticationService,
  TourDashboardService,
  HttpService,
  GetProfileService,
  UploadimgService,
  ToursService,
  ConcertsListService
} from './_services';
import { AuthGuard, AuthInterceptor } from './_guards';
import { PhonePipe, FilterPipe } from './_pipes';
import { ToastrModule } from 'ngx-toastr';
import {
  DashboardTourComponent,
  TourReservationComponent,
  TourReservationDetailsComponent,
  CreateReservationTourComponent,
  TourHomeComponent,
  TripDetailsComponent,
  TourCreateComponent,
  TourDetailsComponent,
  CancelComponent
} from './_verticals/tour';
import {
  LoginComponent,
  ProfileComponent,
  ChangePasswordComponent
} from './_auth';
import { NavigationComponent } from './_components';
import { LoginLayoutComponent, HomeLayoutComponent } from './_layouts';
import {
  FinancialComponent,
  GuestListComponent,
  GuestListItemComponent,
  GlobalSettingComponent,
  ManageUserComponent,
  CreateManageUserRoleComponent,
  TimeFormat
} from './_shared';

import {
  ClickColorDirective,
  MaxNumberValidatorDirective,
  MinNumberValidatorDirective
} from './_directives';
import {
  DashboardSprotsComponent,
  ListOfCoursesComponent,
  CreateEventComponent,
  TrainersComponent,
  NewTrainerComponent
} from './_verticals/sports';
import { AppRoutingModule, AppMaterialModule } from './_core';
import { BranchNameValiator } from './_validators';
import {
  DashboardRestaurantComponent,
  ReservationsRestaurantComponent,
  BranchesRestaurantComponent,
  ReservationDetailsComponent,
  CreateReservationComponent,
  AnonymousReservationComponent,
  CreateBranchComponent,
  BranchDetailsComponent,
  ReviewsComponent,
  EventsComponent
} from './_verticals/restaurant';

import {
  MemberShipListComponent,
  CreateMemberShipComponent,
  DetailsMemberShipComponent
} from './_verticals/sports/member-ship-list/member-ship-list.component';
import {
  AttendenceComponent,
  CreateattendenceComponent,
  ShowattendenceComponent,
  ShowdatetimeComponent,
} from './_verticals/sports/attendence/attendence.component';

import {
  ConcertsDashboardComponent,
  CDActionsComponent,
  CDInsightsComponent,
  CDEventListComponent,
  CDRecentReservationComponent,
  CDSoldTicketsComponent,
  ConcertsReservationsComponent,
  CRTotalReservationsComponent,
  CRReservationsDataComponent,
  CRReservationsDetailsComponent,
  ConcertsEventsComponent,
  CEEventsDataComponent,
  CECreateEventComponent,
  CEBasicInfoComponent,
  CEEventsInformationComponent,
  CEEventDetailsComponent,
  ConcertsCheckInComponent,
  ConcertsCreateReservationComponent,
  ConcertsVenuesComponent,
  CEVenuesComponent,
  createSeats
} from './_verticals/concerts';
import { GlobalSettingResComponent } from './_shared/global-setting-res/global-setting-res.component';
import { TrainerDetailsComponent } from './_verticals/sports/trainers/new-trainer/trainer-details/trainer-details.component';
import { EventDetailsComponent }
  from './_verticals/sports/list-of-courses/event-details/event-details.component';
import { CreateBranchPopupComponent }
  from './_verticals/sports/list-of-courses/create-event/create-event.component';
import { CarsComponent } from './_verticals/cars/cars.component';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaLoaderService,
  RecaptchaModule,
  RecaptchaSettings
} from 'ng-recaptcha';

import { GrdFilterPipe } from './_shared/grd-filter.pipe';
import { SeatsShowComponent } from './_verticals/seats/seats-show/seats-show.component';
import { EventsEditComponent } from './_verticals/restaurant/events/events-edit/events-edit.component';

const globalSettings: RecaptchaSettings = {
  siteKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU'
};

@NgModule({
  declarations: [
    /*directives*/
    MaxNumberValidatorDirective,
    MinNumberValidatorDirective,
    /*Components*/
    DashboardRestaurantComponent,
    ReservationsRestaurantComponent,
    BranchesRestaurantComponent,
    ReservationDetailsComponent,
    CreateReservationComponent,
    AnonymousReservationComponent,
    CreateBranchComponent,
    CreateBranchComponent,
    BranchDetailsComponent,
    AppComponent,
    LoginComponent,
    DashboardTourComponent,
    NavigationComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    FinancialComponent,
    GuestListComponent,
    GuestListItemComponent,
    ProfileComponent,
    ReviewsComponent,
    ChangePasswordComponent,
    /*Pipes*/
    PhonePipe,
    GrdFilterPipe,
    FilterPipe,
    TourReservationComponent,
    TourReservationDetailsComponent,
    CreateReservationTourComponent,
    TourHomeComponent,
    TripDetailsComponent,
    TourCreateComponent,
    GlobalSettingComponent,
    ClickColorDirective,
    TourDetailsComponent,
    MaxNumberValidatorDirective,
    MinNumberValidatorDirective,
    DashboardSprotsComponent,
    ListOfCoursesComponent,
    CreateEventComponent,
    MemberShipListComponent,
    CreateMemberShipComponent,
    DetailsMemberShipComponent,
    AttendenceComponent,
    TrainersComponent,
    CreateattendenceComponent,
    ShowattendenceComponent,
    NewTrainerComponent,
    DashboardSprotsComponent,
    ConcertsDashboardComponent,
    CDActionsComponent,
    CDInsightsComponent,
    CDEventListComponent,
    CDRecentReservationComponent,
    CDSoldTicketsComponent,
    ConcertsReservationsComponent,
    CRTotalReservationsComponent,
    CRReservationsDataComponent,
    CRReservationsDetailsComponent,
    ConcertsEventsComponent,
    CEEventsDataComponent,
    CECreateEventComponent,
    CEBasicInfoComponent,
    CEEventsInformationComponent,
    CEEventDetailsComponent,
    ManageUserComponent,
    CreateManageUserRoleComponent,
    ConcertsCheckInComponent,
    ConcertsVenuesComponent,
    ConcertsCreateReservationComponent,
    CEVenuesComponent,
    EventsComponent,
    EventsEditComponent,
    TimeFormat,
    CancelComponent,
    GlobalSettingResComponent,
    TrainerDetailsComponent,
    EventDetailsComponent,
    CreateBranchPopupComponent,
    CarsComponent,
    SeatsShowComponent,
    createSeats,
    ShowdatetimeComponent,
    EventsEditComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppMaterialModule,
    NgxPaginationModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    // google map
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw',
      libraries: ['places']
    }),
    ChartsModule,
    RecaptchaModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings
    },
    AuthGuard,
    AuthenticationService,
    GetProfileService,
    HttpService,
    TourDashboardService,
    ToursService,
    UploadimgService,
    BranchNameValiator,
    SportsListService,
    ConcertsListService
  ],
  entryComponents: [
    TourReservationDetailsComponent,
    CreateReservationTourComponent,
    TourReservationComponent,
    ReservationDetailsComponent,
    AnonymousReservationComponent,
    CreateReservationComponent,
    CreateMemberShipComponent,
    DetailsMemberShipComponent,
    CreateattendenceComponent,
    ShowattendenceComponent,
    CreateReservationComponent,
    CRReservationsDetailsComponent,
    CancelComponent,
    createSeats,
    ShowdatetimeComponent,
    CreateBranchPopupComponent
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);
