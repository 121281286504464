import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/index';
import { map } from 'rxjs/operators';
import { RouteConfigLoadStart } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class GetProfileService {
	private kk = '/vendor/details';

	reqHeader = new HttpHeaders({ 'content-type': 'application/json' });

	constructor(private http: HttpClient) {}

	getProfile() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/details`, {
				headers: this.reqHeader
			})
			.pipe(
				map(user => {
					// this consle to know user name are visable
					console.log("Profile Data Getting", user);
					return user;
				})
			);
	}

	userprofile(): Observable<any> {
		const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/user/info/?client_id=${
					environment.clientId
				}&client_secret=${environment.clientSecret}`,
				{ headers: reqHeader }
			)
			.pipe(
				map(user => {
					return user;
				})
			);
	}

	EditUserPrfoile(data): Observable<any> {
		const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/user/info/?client_id=${
					environment.clientId
				}&client_secret=${environment.clientSecret}`,
				data,
				{ headers: reqHeader }
			)
			.pipe(
				map(user => {
					return user;
				})
			);
	}

	UpdatePassword(oldpassword, newpassword): Observable<any> {
		const data = {
			old_password: oldpassword,
			new_password: newpassword
		};
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/changepassword/?client_id=${
					environment.clientId
				}&client_secret=${environment.clientSecret}`,
				data,
				{ headers: this.reqHeader }
			)
			.pipe(
				map(user => {
					return user;
				})
			);
	}
	getBasicInforest() {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/restaurant/basicInfo/?client_id=${
					environment.clientId
				}&client_secret=${environment.clientSecret}`,
				{ headers: this.reqHeader }
			)
			.pipe(
				map(user => {
					// this consle to know user name are visable
					console.log('Profile Data Getting', user);
					return user;
				})
			);
	}

	getBasicInfo() {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/tour/basicInfo/?client_id=${
					environment.clientId
				}&client_secret=${environment.clientSecret}`,
				{ headers: this.reqHeader }
			)
			.pipe(
				map(user => {
					// this consle to know user name are visable
					console.log('Profile Data Getting', user);
					return user;
				})
			);
	}
	checkPhoneExists(phone) {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`, {
				headers: this.reqHeader
			})
			.pipe(
				map(user => {
					// this consle to know user name are visable
					console.log('Profile Data Getting', user);
					return user;
				})
			);
	}
	editBasicInfoTour(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/tour/basicInfo/`, data)
			.pipe(
				map(WF => {
					console.log('<><>><><><>', WF);
					return WF;
				})
			);
	}
	getBasicInfoResturant(data) {
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/restaurant/basicInfo/`,
				data
			)
			.pipe(
				map(BS => {
					console.log('<><>><><><>', BS);
					return BS;
				})
			);
	}

  getBasicInfocuisine() {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/restaurant/cuisine/`
			)
			.pipe(
				map(BS => {
					console.log('<><>><><><>', BS);
					return BS;
				})
			);
	}

  getBasicInfotags() {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/restaurant/tags/`
			)
			.pipe(
				map(BS => {
					console.log('<><>><><><>', BS);
					return BS;
				})
			);
	}
	setGuestInfo(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/userbadge/`, data)
			.pipe(
				map(BS => {
					// console.log('<><>><><><>', BS);
					return BS;
				})
			);
	}
}
