import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { imgSrcToBlob } from 'blob-util';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { MatStepper } from '@angular/material';
import {
  HttpService,
  ToursService,
  UploadimgService
} from '../../../../_services';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-events-edit',
  templateUrl: './events-edit.component.html',
  styleUrls: ['./events-edit.component.scss']
})
export class EventsEditComponent implements OnInit {
  eventAction: any;
  eventInfo: {};
  event_Id: any;
  selectedFiles: FileList;
  imageSrc;
  firstFormGroup: FormGroup;
  cost: any = {}
  selected: any;
  SavedInfo: any = {};
  BasicInfo: any = { costs: [] };
  phones: any = [];
  phone: any;
  logoImage: any;
  visible: any;
  newPhone: any;
  editphone = false;
  category: any;
  touredit = false;
  restedit = false;
  card: any;
  working_from;
  working_to;
  card_small: any;
  dressCodes = [
    { id: 1, name: 'formal' },
    { id: 2, name: 'Semi Formal' },
    { id: 3, name: 'Casual' },
    { id: 4, name: 'Smart Casual' }

  ];
  foodNames = [
    { id: 1, name: 'Italitan' },
    { id: 2, name: 'Chainase' },
    { id: 3, name: 'Egyption' }
  ];
  gallery: any[] = [];
  menu: any[] = [];
  mobile: any;
  file: any;
  imageSrcs: string | ArrayBuffer;
  editphoness: any;
  galleryImages: any;
  imageSrcsw: string | ArrayBuffer;
  galleryImagesmenu: FileList;
  menus: any[] = [];
  editField: any;
  CoverImage: any;
  smCoverImage: any;
  menuImages: any;
  img_c: any;
  imageSrcc: string | ArrayBuffer;
  img_cw: any;
  imageSrccw: string | ArrayBuffer;
  galleryImagesww: any;
  imageSrcsyy: string | ArrayBuffer;
  galleryImagemenus: any;
  selectedFile: ImageSnippet;
  image_name: string;
  sazvz: File;
  coverfile: File;
  gallaryfile: File;
  menufile: File;
  image_nameC: string;
  selectedFileC: ImageSnippet;
  selectedgallary: ImageSnippet;
  selectedmenu: ImageSnippet;
  gallarylist: File;
  image_nameG: string;
  startdate: string;
  DataF: any;
  selectedTourDate: any;
  selectedTourDate2: any
  enddate: string;
  workflows: any;
  workflow = true;
  viewThreeHold = false;
  payment_methods: any;
  file_Size_Gallery: number;
  newGallaery = false;
  branchs: any;
  eventNamaAction;
  constructor(private route: ActivatedRoute, private router: Router,
    private service: ToursService, private uploadimgService: UploadimgService,
    private _httpService: HttpService

  ) { }
  ngOnInit(): void {
    this.getSnapshotUrlData();
    // this.working_to = '14:59';
    // this.selectedTourDate2 = '2024-06-19'
    this.service.getPaymentMethod().subscribe(data => {
      console.log(data, 'workflow')
      this.payment_methods = data['payment_methods']
    })
    this.service.getBranchData().subscribe(data => {
      console.log(data, 'branchs');
      this.branchs = data['branches'];
    })
  }

  getSnapshotUrlData() {
    this.eventAction = this.route.snapshot.paramMap.get('action');
    if (this.eventAction === 'edit') {
      console.log('eidt')

    } else if (this.eventAction === 'create') {
      console.log('create');
      this.eventNamaAction = 'Create';
      // this.BasicInfo = {};
      console.log(this.eventInfo);
    }
    else {
      this.event_Id = this.eventAction;
      console.log(this.event_Id)
      this.service.getEventId(this.event_Id).subscribe(data => {
        console.log(data);
        this.BasicInfo = data['event'];
        this.eventNamaAction = data['event']['title']
        this.selectedTourDate = data['event']['start_date'];
        this.selectedTourDate2 = data['event']['end_date'];
        this.working_to = data['event']['end_time'];
        this.working_from = data['event']['start_time'];
        this.galleryImages = data['event']['gallery'];
        this.BasicInfo.hotline = data['event']['mobile'];
        this.BasicInfo.dress_code = data['event']['dress_code']['id'];
        this.BasicInfo.payment_method_id = data['event']['payment_methods'][0]['id'];
      })

    }
  }
  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }
  updateList(id: number, property: any, event: any) {
    console.log(id, property, event)

    const editField = event.target.textContent;

    this.BasicInfo['costs'][id][property] = editField;
    console.log(this.BasicInfo['costs'], 'push');


  }
  selectgalary(imageInput: any) {
    // this.BasicInfo.is_editing_gallery = 1;
    this.BasicInfo.gallery = [];

    for (var i = 0; i < imageInput.files.length; i++) {
      console.log(i)
      let file: File = imageInput.files[i];
      let fileName = file.name;
      // console.log();
      this.file_Size_Gallery = file.size;

      console.log(file, 's')
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        this.selectedgallary = new ImageSnippet(event.target.result, file);
        console.log(this.selectedgallary)
        var cardName = Date.now();
        //  console.log(this.selectedgallary , 'fafas')
        this.gallaryfile = this.dataURLtoFile(this.selectedgallary.src, cardName + '_' + i + '.jpg');
        const path = 'restaurant/gallery';
        this.uploadimgService.uploadFile(this.gallaryfile, path)


        this.gallery.push(this.gallaryfile.name);

        // // this.image_nameC= this.coverfile.name
        console.log(this.gallery, 'gfafafa');
        // let file1 = this.img_o.item(0);
        // console.log(file1 , 's')
        // console.log('POP', file1);
      });
      reader.readAsDataURL(file);

    }
    this.newGallaery = true;

    this.BasicInfo.gallery = this.gallaryfile.name;
    this.BasicInfo.gallery.push(this.gallaryfile.name);

  }
  dataURLtoFile(dataurl, filename) {
    // console.log("data in image", dataurl, filename);
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  setDate6(date) {
    const dateISO = new Date(date);
    this.startdate =
      dateISO.getFullYear() +
      '-' +
      (dateISO.getMonth() + 1) +
      '-' +
      dateISO.getDate();
    console.log('apapppapap ', this.startdate);
  } setDate7(date) {
    const dateISO = new Date(date);
    this.enddate =
      dateISO.getFullYear() +
      '-' +
      (dateISO.getMonth() + 1) +
      '-' +
      dateISO.getDate();
    console.log('apapppapap ', this.enddate);
  }
  checkresev(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.can_reserve = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.can_reserve = false;
    }
  } checkactive(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.active = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.active = false;

    }
  }
  checkreque(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.require_fb = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.require_fb = false;

    }
  }
  getworkflow(e) {
    console.log(e)
    this.BasicInfo.payment_method_id = e;

    if (e == 1) {
      this.service.getWorkFlowPayment(2).subscribe(data => {
        console.log(data);
        this.workflows = data['workflows'];
        this.workflow = false;

      })
    }
    else {
      this.service.getWorkFlowPayment(1).subscribe(data => {
        console.log(data);
        this.workflows = data['workflows'];
        this.workflow = false;
        this.viewThreeHold = true;


      })
    }
  }
  myFilter = (d: Date): boolean => {
    const showDates = this.DataF.filter(function (el) {
      return el !== '';
    });
    return (
      showDates.findIndex(
        showDate => d.toDateString() === new Date(showDate).toDateString()
      ) >= 0
    );
  };
  addcost(cost) {

    this.BasicInfo['costs'].push({
      "name": cost.name,
      "amount": cost.amu,
    });
  }
  SubmitBasicInfo(data) {
    if (this.eventAction == 'create') {
      console.log(this.newGallaery)
      if (this.newGallaery == true) {
        if (this.gallery.length > 0) {
          this.BasicInfo.gallery = this.gallery;
        }
      }
      if (this.working_from == ":00" && this.working_to == ":00") {
        console.log('3')

        this.working_from = ''
        this.working_to = '';
        // console.log('a7a')
      }

      else {
        console.log('4')
        if (this.working_from == null && this.working_to == null) {
          console.log('4-2')

          console.log(this.working_from, this.working_to, this.BasicInfo, 'null')
          this.working_from = null
          this.working_to = null;
        }
        else if (this.working_from.length == 5 && this.working_to.length == 5) {
          console.log('4.5')

          console.log('a7a7a7a')
          this.working_from = this.working_from + ':' + '00';
          this.working_to = this.working_to + ':' + '00';

        }
        else {
          console.log('5')
          this.working_from = this.working_from
          this.working_to = this.working_to
        }
      }

      if (!this.BasicInfo.title || !this.BasicInfo.branch_guid || !this.BasicInfo.description
        || !this.BasicInfo.dress_code || !this.working_from
        || !this.BasicInfo.payment_method_id || !this.BasicInfo.workflow || !this.working_to
        || this.BasicInfo.costs.length == 0 || this.BasicInfo.gallery.length == 0) {
        swal('please provide missing data', '', 'error');
        console.log(this.BasicInfo)
      }


      else {
        this.BasicInfo.from = this.startdate + ' ' + this.working_from
        this.BasicInfo.to = this.enddate + ' ' + this.working_to
        this.BasicInfo.active = true;
        console.log(data)
        this.service.addEvent(this.BasicInfo).subscribe(
          data => {
            console.log(this.BasicInfo, 'rest');
            swal('Golbal Setting Was Updated', 'Success', 'success');
            this.router.navigate(['/R/events']);
            console.log('data', data);
          },
          error => {
            console.log(error, ' error');
            swal('Error in Sumbit Data', 'Error', 'error');
          }
        );

      }

    }
    else {
      console.log('ahaagaafa');
      console.log(this.newGallaery)
      // if (this.newGallaery == true) {
      //   if (this.gallery.length > 0) {
      //     this.BasicInfo.gallery = this.gallery;
      //   }
      // }
      this.BasicInfo.from = this.startdate + ' ' + this.working_from
      this.BasicInfo.to = this.enddate + ' ' + this.working_to

      var DataEdit = {
        "branch_guid": this.BasicInfo.branch_guid,
        "from": this.BasicInfo.from,
        "to": this.BasicInfo.to,
        "title": this.BasicInfo.description,
        "description": this.BasicInfo.description,
        "house_rules": this.BasicInfo.house_rules,
        "dress_code": this.BasicInfo.dress_code,
        "hotline": this.BasicInfo.hotline,
        "workflow": this.BasicInfo.workflow,
        "can_reserve": this.BasicInfo.can_reserve,
        "require_fb": this.BasicInfo.require_fb,
        "payment_method_id": this.BasicInfo.payment_method_id,
        "active": this.BasicInfo.active,
        "threshold": this.BasicInfo.threshold,
        "gallery": this.BasicInfo.gallery,
        "costs": this.BasicInfo.costs
      }

      console.log(DataEdit, 'Edit');

    }

  }

}
