
import { Component, OnInit, Query } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UploadimgService, GetProfileService } from '../../_services';
import swal from 'sweetalert2';
import * as S3 from 'aws-sdk/clients/s3';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-global-setting-res',
  templateUrl: './global-setting-res.component.html',
  styleUrls: ['./global-setting-res.component.scss']
})
export class GlobalSettingResComponent implements OnInit {
  selectedFiles: FileList;
  imageSrc;
  firstFormGroup: FormGroup;
  selected: any;
  SavedInfo: any = {};
  BasicInfo: any = {};
  phones: any = [];
  phone: any;
  logoImage: any;
  visible: any;
  newPhone: any;
  editphone = false;
  category: any;
  touredit = false;
  restedit = false;
  card: any;
  card_small: any;
  dressCodes = [
    { id: 1, name: 'formal' },
    { id: 2, name: 'Semi Formal' },
    { id: 3, name: 'Casual' },
    { id: 4, name: 'Smart Casual' }

  ];
  foodNames = [
    { id: 1, name: 'Italitan' },
    { id: 2, name: 'Chainase' },
    { id: 3, name: 'Egyption' }
  ];
  gallery: any[] = [];
  menu: any[] = [];
  mobile: any;
  file: any;
  imageSrcs: string | ArrayBuffer;
  editphoness: any;
  galleryImages: any;
  imageSrcsw: string | ArrayBuffer;
  galleryImagesmenu: FileList;
  menus: any[] = [];
  editField: any;
  CoverImage: any;
  smCoverImage: any;
  menuImages: any;
  img_c: any;
  imageSrcc: string | ArrayBuffer;
  img_cw: any;
  imageSrccw: string | ArrayBuffer;
  galleryImagesww: any;
  imageSrcsyy: string | ArrayBuffer;
  galleryImagemenus: any;
  selectedFile: ImageSnippet;
  image_name: string;
  sazvz: File;
  coverfile: File;
  gallaryfile: File;
  menufile: File;
  image_nameC: string;
  selectedFileC: ImageSnippet;
  selectedgallary: ImageSnippet;
  selectedmenu: ImageSnippet;
  gallarylist: File;
  image_nameG: string;
  public widthCard: number;
  public heightCard: number;
  public widthSmallCard: number;
  public heightSmallCard: number;
  public widthLogo: number;
  public heightLogo: number;
  public widthGallery: number;
  public heightGallery: number;
  public widthMenu: number;
  public heightMenu: number;
  public file_Size_logo: number;
  public file_Size_card: number;
  public file_Size_Small_card: number;
  public file_Size_Gallery: number;
  public file_Size_Menu: number;
  public AspectRatioLogo: number;
  public AspectRatioCard: number;
  public AspectRatioSmallCard: number;
  public AspectRatioGallary: number;
  public AspectRatioMenu: number;
  public ReselutionLogo: number;
  public ReselutionCard: number;
  public ReselutionSmallCard: number;
  smalcard: File;

  top: any;
  toppingList = [
    { id: 1, name: "Extra cheese" },
    { id: 2, name: "Mushroom" },
    { id: 4, name: "Sausage" },
    { id: 5, name: "Tomato" },
    { id: 9, name: "Pepperoni" }
  ];
  catageroy: any[] = [];
  cuisine: any;
  categories: any;
  cuisines: any;
  datafi: any[] = [];
  constructor(
    private uploadimgService: UploadimgService,
    private getProfileService: GetProfileService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getProfileService.getBasicInforest().subscribe((data: any) => {
      this.SavedInfo = data.restaurant;

      console.log(this.SavedInfo);
      // console.log(this.SavedInfo.phones , 'phone');


      this.getProfileService.getBasicInfocuisine().subscribe(data => {
        console.log(data, 'cusisni')
        this.cuisines = data['cuisines']
      })
      this.getProfileService.getBasicInfotags().subscribe(data => {
        console.log(data, 'tags')
        this.categories = data['tags']
      })
      this.SetData();

    });


  }
  filter(data: any) {
    console.log(data);
  }
  getfoodaname(data) {
    console.log(data)
    this.BasicInfo.cuisine = data;

    this.BasicInfo.cuisine = data;
    // this.SavedInfo.cuisine.guid = data;
  }
  showdata = true
  SetData() {
    this.showdata = false;

    this.BasicInfo.name = this.SavedInfo.name;
    this.BasicInfo.phones = this.SavedInfo.phones;
    this.BasicInfo.website = this.SavedInfo.website;
    this.BasicInfo.facebook = this.SavedInfo.facebook;
    this.BasicInfo.description = this.SavedInfo.description;
    this.BasicInfo.dress_code = this.SavedInfo.dress_code.id;
    this.BasicInfo.hotline = this.SavedInfo.hotline;
    this.BasicInfo.tags = this.catageroy;
    this.BasicInfo.max = this.SavedInfo.range_max;
    this.BasicInfo.min = this.SavedInfo.range_min;
    this.BasicInfo.avg = this.SavedInfo.range_avg;
    this.BasicInfo.working_from = this.SavedInfo.working_from;
    this.BasicInfo.working_to = this.SavedInfo.working_to;
    this.BasicInfo.require_fb = this.SavedInfo.require_fb;
    this.BasicInfo.can_reserve = this.SavedInfo.can_reserve;
    this.BasicInfo.has_note = this.SavedInfo.has_note;
    this.BasicInfo.is_verified = this.SavedInfo.is_verified;
    this.BasicInfo.note = this.SavedInfo.note;
    this.BasicInfo.instagram = this.SavedInfo.instagram;
    this.BasicInfo.discount = this.SavedInfo.discount;
    if (this.SavedInfo.tags.length > 0) {
      for (let i of this.SavedInfo.tags) {
        // console.log(i);
        this.catageroy.push(i.guid)
        // console.log(this.catageroy)
      }
    }


    // this.BasicInfo.logo = "";
    // this.BasicInfo.card = "";
    // this.BasicInfo.menu = [];
    // this.BasicInfo.gallery = [];
    // this.BasicInfo.card_small = "";
    this.logoImage = this.SavedInfo.logo;
    this.CoverImage = this.SavedInfo.card;
    this.smCoverImage = this.SavedInfo.card_small;
    this.galleryImages = this.SavedInfo.gallery;
    this.menuImages = this.SavedInfo.menu;
    if (this.SavedInfo.cuisine == null) {
      this.BasicInfo.cuisine.guid = "";
    }
    else {
      this.BasicInfo.cuisine = this.SavedInfo.cuisine.guid || "";

    }
    console.log(this.SavedInfo.tags)

  }
  pdfFiles: any[] = [];
  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
    console.log(event.target['currentSrc'])
    this.pdfFiles.push(event.target['currentSrc'])
    console.log(this.pdfFiles)
  }
  checkNote(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.has_note = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.has_note = false;

    }
  }
  checkVerfiy(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.is_verified = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.is_verified = false;

    }
  }
  checkresev(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.can_reserve = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.can_reserve = false;

    }
  }
  checkreque(e) {
    console.log(e)
    if (e.checked) {
      this.BasicInfo.require_fb = true;
    }
    else if (e.checked == false) {
      this.BasicInfo.require_fb = false;

    }
    // if(this.BasicInfo.require_fb == false){
    //   this.BasicInfo.require_fb = true;

    // }
    // else{
    //   this.BasicInfo.require_fb = false;

    // }
  }
  dataURLtoFile(dataurl, filename) {
    // console.log("data in image", dataurl, filename);
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }



  selectFile(imageInput: any) {

    let file: File = imageInput.files[0];

    console.log(file.size, 'logo mage123')
    const reader = new FileReader();

    /// Check for Image Ration and Size
    reader.onload = () => { // when file has loaded
      const img = new Image();

      img.onload = () => {
        this.widthLogo = img.width;
        this.heightLogo = img.height;
        this.file_Size_logo = file.size;
        console.log(this.file_Size_logo, 'krkrkrk1');
        console.log(this.heightLogo + '  ' + this.widthLogo);
        this.AspectRatioLogo = this.widthLogo / this.heightLogo;
        this.ReselutionLogo = this.widthLogo * this.heightLogo;
        console.log(this.AspectRatioLogo);
        if (this.AspectRatioLogo !== 1) {
          Swal('Please Upload Logo With Ratio 1', 'error', 'error');
          file = null;
        }
        else if (this.file_Size_logo > 100000) { // 100KB
          console.log(this.file_Size_logo, 'krkrkrk2');
          Swal('Please Upload Logo with Max Size 100KB', 'error', 'error');
          file = null;
        } else {
          const path = 'vendor/logo';
          this.uploadimgService.uploadFile(this.sazvz, path);
          this.BasicInfo.logo = this.sazvz.name;
        }
      };

      img.src = reader.result; // This is the data URL
    };
    /// End of check code
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      console.log(this.selectedFile)
      var cardName = Date.now();
      //  console.log(this.selectedFile , 'fafas')
      this.sazvz = this.dataURLtoFile(this.selectedFile.src, cardName + '.jpg');
      // this.BasicInfo.logo = this.sazvz.name;
      this.BasicInfo.logo = file; // sending the file insted of source
      console.log(this.sazvz.name, 'gfafafa');
    });
    reader.readAsDataURL(file);
    this.BasicInfo.gallery = null;
    this.BasicInfo.menu = null;
    console.log(this.AspectRatioLogo, 'have to');



    //   // let file1 = this.img_o.item(0);
    //   // console.log(file1 , 's')
    // 	// console.log('POP', file1);
    /* files: FileList
      0: File(4956)
      { name: "zab.jpg",
        lastModified: 1540237663463,
        lastModifiedDate: Mon Oct 22 2018 21:47:43 GMT+0200 (Eastern European Standard Time),
        webkitRelativePath: "", size: 4956, …}
*/
    // this.logoImage = event.target.files;
    // const path = 'vendor/logo';
    // const file = this.logoImage.item(0);
    // this.uploadimgService.uploadFile(file, path);
    // this.BasicInfo.logo = file.name;
    // console.log(this.selectFile);
    // if (event.target.files && event.target.files[0]) {
    // 	const h = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.logoImage = reader.result);
    // 	reader.readAsDataURL(h);
    // }

    // const formData = new FormData();
    // formData.append('file', file);
    //  reader.onload = () => { // when file has loaded
    //       const img = new Image();

    //       img.onload = () => {
    //           this.widthLogo = img.width;
    //           this.heightLogo = img.height;
    //           console.log(this.heightLogo + '  ' + this.widthLogo);
    //           this.AspectRatioLogo = this.widthLogo / this.heightLogo;
    //           console.log(this.AspectRatioLogo);
    //       };

    //       img.src = reader.result; // This is the data URL
    //   };

  }



  selectcard(imageInput: any) {

    let file: File = imageInput.files[0];
    this.file_Size_card = file.size;
    console.log(file, 'cardddd')

    const reader = new FileReader();

    reader.onload = () => { // when file has loaded
      const img = new Image();

      img.onload = () => {
        this.widthCard = img.width;
        this.heightCard = img.height;
        console.log(this.heightCard + '  ' + this.widthCard);
        this.AspectRatioCard = this.widthCard / this.heightCard;
        this.ReselutionCard = this.widthCard * this.heightCard;
        console.log(this.AspectRatioCard);
        if (this.AspectRatioCard <= 1.7 || this.AspectRatioCard >= 1.9) {
          Swal('Please Upload Card Ratio 1.7 - 1.9', 'error', 'error');
          file = null;
        }
        else if (this.file_Size_card > 500000) { // 100KB
          console.log(this.file_Size_card, 'krkrkrk2');
          Swal('Please Upload Card with Max Size 500KB ', 'error', 'error');
          file = null;
        } else {
          const path = 'restaurant/card';
          this.uploadimgService.uploadFile(this.coverfile, path);  //// =====> two
          this.BasicInfo.card = this.coverfile.name;

        }
      };

      img.src = reader.result; // This is the data URL
    };


    reader.addEventListener('load', (event: any) => {
      this.selectedFileC = new ImageSnippet(event.target.result, file);
      console.log(this.selectedFileC)
      var cardName = Date.now();
      console.log(this.selectedFileC, 'fafas')
      this.coverfile = this.dataURLtoFile(this.selectedFileC.src, cardName + '.jpg');
      // this.BasicInfo.card = this.coverfile.name
      // this.BasicInfo.card = file;   //// ======> one
      console.log(this.coverfile.name, 'gfafafa');
      //   // let file1 = this.img_o.item(0);
      //   // console.log(file1 , 's')
      // 	// console.log('POP', file1);
    });
    console.log(this.BasicInfo.card, 'testetest');
    reader.readAsDataURL(file);

    this.BasicInfo.gallery = null;
    this.BasicInfo.menu = null;

    //     const image: any = imageInput.files[0];
    //     // this.size = image.size;
    //     // const fr = new FileReader;
    //     fr.onload = () => { // when file has loaded
    //      const img = new Image();

    //      img.onload = () => {
    //          this.width = img.width;
    //          this.height = img.height;
    //          console.log(this.width + '  ' + this.width);
    //      };

    //      img.src = fr.result; // This is the data URL
    //  };

    //    fr.readAsDataURL(image);



    // this.CoverImage = event.target.files;
    // const path = 'restaurant/card';
    // const file = this.card.item(0);
    // this.uploadimgService.uploadFile(file, path);
    // this.BasicInfo.card = file.name;
    // console.log(this.selectcard);
    // if (event.target.files && event.target.files[0]) {
    //   const t = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.onload = e => (this.card = reader.result);
    //   reader.readAsDataURL(t);
    // }

    // this.img_c = event.target.files;
    // //   this.img_2 = this.img_c[0].name;
    // //   this.img_2 = Date.now() + '.jpg';
    // console.log(this.img_c[0].name, 'Wfa');
    // if (event.target.files && event.target.files[0]) {
    // 	// tslint:disable-next-line: no-shadowed-variable
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // this.BasicInfo.card = this.img_c.item(0).name;
    // 	reader.onload = e => (this.imageSrcc = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    // const path = 'restaurant/card';
    // const file = this.img_c.item(0);
    // this.uploadimgService.uploadFile(file, path);

  }


  selectsmallcard(imageInput: any) {
    // this.smCoverImage = event.target.files;
    // const path = 'restaurant/card';
    // const file = this.card.item(0);
    // this.uploadimgService.uploadFile(file, path);
    // this.BasicInfo.card_small = file.name;
    // console.log(this.selectcard);
    // if (event.target.files && event.target.files[0]) {
    //   const w = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.onload = e => (this.card_small = reader.result);
    //   reader.readAsDataURL(w);
    // }
    // this.img_cw = event.target.files;
    // //   this.img_2 = this.img_c[0].name;
    // //   this.img_2 = Date.now() + '.jpg';
    // console.log(this.img_cw[0].name, 'Wfa');
    // if (event.target.files && event.target.files[0]) {
    // 	// tslint:disable-next-line: no-shadowed-variable
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	this.BasicInfo.card_small = this.img_cw.item(0).name;
    // 	reader.onload = e => (this.imageSrccw = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    // const path = 'restaurant/card';
    // const file = this.img_cw.item(0);
    // this.uploadimgService.uploadFile(file, path);

    let file: File = imageInput.files[0];
    this.file_Size_Small_card = file.size;

    console.log(file)
    const reader = new FileReader();

    reader.onload = () => { // when file has loaded
      const img = new Image();

      img.onload = () => {
        this.widthSmallCard = img.width;
        this.heightSmallCard = img.height;
        console.log(this.heightSmallCard + '  ' + this.widthSmallCard);
        this.AspectRatioSmallCard = this.widthSmallCard / this.heightSmallCard;
        this.ReselutionSmallCard = this.widthSmallCard * this.heightSmallCard;
        console.log(this.AspectRatioSmallCard);
        if (this.AspectRatioSmallCard < 1.3 || this.AspectRatioSmallCard > 1.5) {
          Swal('Please Upload Card With Ratio 1.3 -1.5', 'error', 'error');
          file = null;
        }
        else if (this.file_Size_Small_card > 500000) { // 100KB
          console.log(this.file_Size_Small_card, 'krkrkrk2');
          Swal('Please Upload Small Card with Max size 500KB ', 'error', 'error');
          file = null;
        } else {
          const path = 'restaurant/card';
          this.uploadimgService.uploadFile(this.smalcard, path);
          this.BasicInfo.card_small = this.smalcard.name;
        }
      };

      img.src = reader.result; // This is the data URL
    };

    reader.addEventListener('load', (event: any) => {
      this.selectedFileC = new ImageSnippet(event.target.result, file);
      console.log(this.selectedFileC)
      var cardName = Date.now();
      console.log(this.selectedFileC, 'fafas')
      this.smalcard = this.dataURLtoFile(this.selectedFileC.src, cardName + '.jpg');
      console.log(this.smalcard.name, 'gfafafa');
      const path = 'restaurant/card';
      //   // let file1 = this.img_o.item(0);
      //   // console.log(file1 , 's')
      // 	// console.log('POP', file1);

    });
    reader.readAsDataURL(file);
    this.BasicInfo.gallery = null;
    this.BasicInfo.menu = null;
  }



  progressInfos = [];
  message = '';
  progress: number;

  selectgalary(imageInput: any) {
    this.BasicInfo.is_editing_gallery = 1;
    this.BasicInfo.gallery = [];

    for (var i = 0; i < imageInput.files.length; i++) {
      console.log(i)
      let file: File = imageInput.files[i];
      let fileName = file.name;
      // console.log();
      this.file_Size_Gallery = file.size;

      console.log(file, 's')
      const reader = new FileReader();
      //   reader.onload = () => { // when file has loaded
      //     const img = new Image();

      //     img.onload = () => {
      //         this.widthGallery = img.width;
      //         this.heightGallery = img.height;
      //         console.log(this.heightGallery + '  ' + this.widthGallery);
      //         this.AspectRatioGallary = this.widthGallery / this.heightGallery;
      //         console.log(this.AspectRatioGallary);
      //         if (this.AspectRatioGallary !== 1 ) {
      //           Swal('Please Upload Card With Dimension 960*960, Image Name :' + fileName + '' , 'error', 'error');
      //           file = null;
      //          }
      //          else if (this.file_Size_Gallery >= 500000) { // 100KB
      //           console.log(this.file_Size_Gallery , 'krkrkrk2');
      //           Swal('Please Upload Card with Max size 500 KB, Image Name :' + fileName + '', 'error', 'error');
      //           file = null;
      //          } else {
      //           const path = 'restaurant/gallery';
      //           this.uploadimgService.uploadFile(this.gallaryfile, path);
      //           file = null;

      //          }
      //     };

      //     img.src = reader.result; // This is the data URL
      // };

      reader.addEventListener('load', (event: any) => {
        this.selectedgallary = new ImageSnippet(event.target.result, file);
        console.log(this.selectedgallary)
        var cardName = Date.now();
        //  console.log(this.selectedgallary , 'fafas')
        this.gallaryfile = this.dataURLtoFile(this.selectedgallary.src, cardName + '_' + i + '.jpg');
        const path = 'restaurant/gallery';
        this.uploadimgService.uploadFile(this.gallaryfile, path)


        this.gallery.push(this.gallaryfile.name);

        // // this.image_nameC= this.coverfile.name
        console.log(this.gallery, 'gfafafa');
        // let file1 = this.img_o.item(0);
        // console.log(file1 , 's')
        // console.log('POP', file1);
      });
      reader.readAsDataURL(file);

    }
    this.BasicInfo.gallery = this.gallaryfile.name;
    this.BasicInfo.gallery.push(this.gallaryfile.name);
    // this.galleryImagesww = event.target.files;
    // console.log(this.galleryImagesww, 'gala');
    // for (let i = 0; i < this.galleryImagesww.length; i++) {
    // 	this.gallery.push(this.galleryImagesww.item(i).name);
    // }
    // console.log(this.gallery, 's');
    // if (event.target.files && event.target.files[0]) {
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.imageSrcsyy = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    // for (let i = 0; i < this.galleryImagesww.length; i++) {
    // 	const path2 = 'restaurant/gallery';
    // 	const file23 = this.galleryImagesww.item(i);
    // 	console.log('POsP', file23);
    // 	this.uploadimgService.uploadFile(file23, path2);
    // }
  }
  progressPercentage: number = 0;
  loadData = false;
  selectmenu(imageInput: any) {
    this.BasicInfo.is_editing_menu = 1;
    this.BasicInfo.menu = [];
    this.loadData = true;
    // this.galleryImagemenus = event.target.files;
    // console.log(this.galleryImagemenus, 'gala');
    // for (let i = 0; i < this.galleryImagemenus.length; i++) {
    // 	this.menus.push(this.galleryImagemenus.item(i).name);
    // }
    // console.log(this.gallery, 's');
    // if (event.target.files && event.target.files[0]) {
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.imageSrcsyy = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    // for (let i = 0; i < this.galleryImagemenus.length; i++) {
    // 	const path2 = 'restaurant/menu';
    // 	const file23 = this.galleryImagemenus.item(i);
    // 	console.log('POsP', file23);
    // 	this.uploadimgService.uploadFile(file23, path2);
    // }
    for (var i = 0; i < imageInput.files.length; i++) {
      console.log(i)
      var sizeTotal = 0;

      let file: File = imageInput.files[i];
      const fileName = file.name;
      sizeTotal += file.size;

      // console.log();
      this.file_Size_Menu = file.size;
      console.log(file, 's')
      const reader = new FileReader();
      //   reader.onload = () => { // when file has loaded  , The Code of Validation Images Start from here
      //     const img = new Image();

      //     img.onload = () => {
      //         this.widthMenu = img.width;
      //         this.heightMenu = img.height;
      //         console.log(this.heightMenu + '  ' + this.widthMenu);
      //         this.AspectRatioMenu = this.widthMenu / this.heightMenu;
      //         console.log(this.AspectRatioMenu);
      //         if (this.AspectRatioMenu <= 0.7 || this.AspectRatioMenu >= 0.9 ) {
      //           Swal('Please Upload Card With Dimension 1127*1280, Image Name :' + fileName + '' , 'error', 'error');
      //           file = null;
      //          }
      //          else if (this.file_Size_Menu >= 500000) { // 100KB
      //           console.log(this.file_Size_Menu , 'krkrkrk2');
      //           Swal('Please Upload Card With Max Size 500 KB, Image Name :' + fileName + '', 'error', 'error');
      //           file = null;
      //          } else {
      //           const path = 'restaurant/menu';
      //           this.uploadimgService.uploadFile(this.menufile, path);
      //           file = null;

      //          }
      //     };

      //     img.src = reader.result; // This is the data URL
      // };
      reader.addEventListener('load', (event: any) => {
        this.selectedmenu = new ImageSnippet(event.target.result, file);
        console.log(this.selectedgallary)
        var cardName = Date.now();
        //  console.log(this.selectedgallary , 'fafas')
        if (file.type == 'application/pdf') {
          this.menufile = this.dataURLtoFile(this.selectedmenu.src, cardName + '_' + i + '.pdf');
        }
        else {
          this.menufile = this.dataURLtoFile(this.selectedmenu.src, cardName + '_' + i + '.jpg');

        }
        // const path = 'restaurant/menu';
        // this.uploadimgService.uploadFile(this.menufile, path);
        console.log(this.menufile.name)
        const bucket = new S3({
          accessKeyId: 'AKIAIPYZV4JUWFFKGTXA',
          secretAccessKey: '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',
          region: 'us-west-2',

        });

        const params = {
          Bucket: 'zabatnee-backend-storage',
          Key: 'restaurant/menu' + '/' + this.menufile.name,
          ContentType: '',
          Body: file,
          ContentEncoding: 'base64',

        };
        const options = {
          // Part Size of 10mb
          partSize: 100 * 1024 * 1024,
          queueSize: 1,
          pathname: '/vendor',
          // Give the owner of the bucket full control
          ACL: 'bucket-owner-full-control'
        };

        var loaded = [];
        var data = []

        var progressDiv = document.getElementById("myProgress");
        progressDiv.style.display = "none";
        var progressBar = document.getElementById("myBar");
        bucket.upload(params, options, (err, data) => {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
          }

          console.log('Successfully uploaded file.', data);
          return true;
        }).on('httpUploadProgress', (e) => {
          console.log(e)
          // console.log(e, 'sfaf')
          // loaded[params.Body.name] = e.loaded;
          // var loadedTotal = 0;
          // for (var j in loaded) {
          //   loadedTotal += loaded[j];
          //   console.log(j , loadedTotal)
          // }
          let progressPercentage = Math.round(e.loaded / e.total * 100);
          console.log(progressPercentage);
          progressBar.style.width = progressPercentage + "%";
          if (progressPercentage < 100) {
            // fileUpload.progressStatus = progressPercentage;

          } else if (progressPercentage == 100) {
            // fileUpload.progressStatus = progressPercentage;
            // fileUpload.status = "Uploaded";
            console.log('done')
            var d = 'done' + 1;
            this.datafi.push(d)
            if (this.menu.length == data.length) {
              this.loadData = false;

            }
          }
          console.log(this.progressPercentage);
          console.log(this.datafi)


          // let progressPercentage = Math.round(progress.loaded / progress.total * 100);
        }
        );


        this.menus.push(this.menufile.name);

        // // this.image_nameC= this.coverfile.name
        console.log(this.menus, 'gfafafa');
        // let file1 = this.img_o.item(0);
        // console.log(file1 , 's')
        // console.log('POP', file1);
      });
      reader.readAsDataURL(file);

    }

    // this.BasicInfo.gallery.push(this.gallery);
    this.BasicInfo.menu = this.menus;
  }
  uploadId: any[] = [];
  menuupload(imageInput: any) {
    for (var i = 0; i < imageInput.files.length; i++) {
      console.log(i)
      var sizeTotal = 0;

      let file: File = imageInput.files[i];
      const fileName = file.name;
      this.file_Size_Menu = file.size;
      console.log(file, 's')
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        this.selectedmenu = new ImageSnippet(event.target.result, file);
        console.log(this.selectedgallary)
        var cardName = Date.now();
        //  console.log(this.selectedgallary , 'fafas')
        if (file.type == 'application/pdf') {
          this.menufile = this.dataURLtoFile(this.selectedmenu.src, cardName + '_' + i + '.pdf');
        }
        else {
          this.menufile = this.dataURLtoFile(this.selectedmenu.src, cardName + '_' + i + '.jpg');

        }
        // const path = 'restaurant/menu';
        // this.uploadimgService.uploadFile(this.menufile, path);
        var index = [1, 2, 3, 4, 5, 6]
        console.log(this.menufile.name, this.dataURLtoFile(this.selectedmenu.src, cardName + '_' + i + '.jpg'))
        const bucket = new S3({
          accessKeyId: 'AKIAIPYZV4JUWFFKGTXA',
          secretAccessKey: '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',
          region: 'us-west-2',

        });
        const par1 = {
          Bucket: 'zabatnee-backend-storage',
          Key: 'restaurant/menu' + '/' + this.menufile.name,

        }


        // var uploadId
        const upload = bucket.createMultipartUpload(par1).promise()
          .then(res => {
            console.log(res)
            this.uploadId.push(res)
            // return res => res.json().data as Query[];

          });

        this.menus.push(this.menufile.name);
        // // this.image_nameC= this.coverfile.name
        console.log(this.menus, 'gfafafa');
        // let file1 = this.img_o.item(0);
        // console.log(file1 , 's')
        // console.log('POP', file1);
      });
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      const bucket = new S3({
        accessKeyId: 'AKIAIPYZV4JUWFFKGTXA',
        secretAccessKey: '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',
        region: 'us-west-2',

      });
      let k = 0;
      for (let o of this.uploadId) {
        // console.log(file, o)
        // let files: File = imageInput.files[i];

        const s3Params = {
          Bucket: 'zabatnee-backend-storage',
          Key: o['Key'],
          Body: imageInput.files[k++],
          PartNumber: 1000,
          UploadId: o['UploadId']
        };
        console.log(this.uploadId, s3Params.Body)
        bucket.uploadPart(s3Params, (err, data) => {
          if (err) {
            console.log(err);
            // return res.status(500).json({ success: false, message: 'Error uploading chunk' });
          }
          console.log(data)
          // return res.json({ success: true, message: 'Chunk uploaded successfully' });
          // });
        });


        setTimeout(() => {
          var s3Params: any = {};
          s3Params = {
            Bucket: 'zabatnee-backend-storage',
            Key: o['Key'],
            UploadId: o['UploadId']
          };


          bucket.listParts(s3Params, (err, data) => {
            if (err) {
              console.log(err);
              // return res.status(500).json({ success: false, message: 'Error listing parts' });
            }
            console.log(data)
            const parts = [];
            data.Parts.forEach(part => {
              console.log()
              parts.push({
                ETag: part.ETag,
                PartNumber: part.PartNumber
              });
            });

            s3Params.MultipartUpload = {
              Parts: parts
            };

            bucket.completeMultipartUpload(s3Params, (err, data) => {
              if (err) {
                console.log(err);
                // return res.status(500).json({ success: false, message: 'Error completing upload' });
              }

              console.log("data: ", data)
              // return res.json({ success: true, message: 'Upload complete', data: data.Location });
            });
          });



        }, 6000);
      }

    }, 5000);


  }
  saveContact(selected, index) {
    this.BasicInfo.phones[index] = selected;
    this.reset();
  }

  checkPhoneExists(phone) {
    if (phone === null) {
      Swal('the phone is null', 'error', 'error');
    } else {
      this.getProfileService.checkPhoneExists(phone).subscribe((data: any) => {
        if (data.code === '10034') {
          this.BasicInfo.phones.push(phone);
          this.visible = false;
          this.newPhone = '';
          this.newPhone = null;
          console.log(this.BasicInfo.phones, 'fsafsa');
        } else {
          Swal(data.detail, 'error', 'error');
        }
      });
    }
  }
  removeContact(index) {
    this.SavedInfo.phones.splice(index, 1);
  }
  reset = function () {
    this.selected = '';
  };
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }
  updateList(id: number, property: any, event: any) {
    const editField = event.target.textContent;
    this.BasicInfo[property][id] = editField;
    if (editField.length > 11) {
      swal('please insert correct number', 'enter number agian', 'error');
    }
    // console.log(this.BasicInfo)
  }
  SubmitBasicInfo(BasicInfo) {
    // this.BasicInfo.gallery = null;
    if (this.menus.length > 0) {
      this.BasicInfo.gallery = null;
      // this.BasicInfo.menu = null;
      this.BasicInfo.menus = this.menus;
      console.log('a');
      if (this.gallery.length > 0) {
        this.BasicInfo.gallery = this.gallery;
      }
    } else if (this.gallery.length > 0) {
      this.BasicInfo.menu = null;
      // this.BasicInfo.gallery = null;
      this.BasicInfo.gallery = this.gallery;
      console.log('s');
      if (this.menus.length > 0) {
        this.BasicInfo.menus = this.menus;
      }
    }
    else {
      this.BasicInfo.menu = null;
      this.BasicInfo.gallery = null;
    }
    console.log(this.BasicInfo.working_from, this.BasicInfo.working_to)
    if (this.BasicInfo.working_from == '' && this.BasicInfo.working_to == '') {
      console.log('1')
      console.log(this.BasicInfo.working_from, this.BasicInfo.working_to, this.BasicInfo, 'null')
      this.BasicInfo.working_from = null
      this.BasicInfo.working_to = null;
    }


    if (this.BasicInfo.working_from == ":00" && this.BasicInfo.working_to == ":00") {
      console.log('3')

      this.BasicInfo.working_from = ''
      this.BasicInfo.working_to = '';
      // console.log('a7a')
    }

    else {
      console.log('4')
      if (this.BasicInfo.working_from == null && this.BasicInfo.working_to == null) {
        console.log('4-2')

        console.log(this.BasicInfo.working_from, this.BasicInfo.working_to, this.BasicInfo, 'null')
        this.BasicInfo.working_from = null
        this.BasicInfo.working_to = null;
      }
      else if (this.BasicInfo.working_from.length == 5 && this.BasicInfo.working_to.length == 5) {
        console.log('4.5')

        console.log('a7a7a7a')
        this.BasicInfo.working_from = this.BasicInfo.working_from + ':' + '00';
        this.BasicInfo.working_to = this.BasicInfo.working_to + ':' + '00';

      }
      else {
        console.log('5')
        this.BasicInfo.working_from = this.BasicInfo.working_from
        this.BasicInfo.working_to = this.BasicInfo.working_to
      }
    }
    console.log(this.BasicInfo.working_from)


    console.log(this.BasicInfo)

    console.log(this.BasicInfo, 'before send dataaaaaaaa123123');
    this.getProfileService.getBasicInfoResturant(this.BasicInfo).subscribe(
      data => {
        console.log(this.BasicInfo, 'rest');
        Swal('Golbal Setting Was Updated', 'Success', 'success');
        this.router.navigate(['/R/branches']);
        console.log('data', data);
      },
      error => {
        console.log(error, ' error');
        Swal('Error in Sumbit Data', 'Error', 'error');
      }
    );
  }

  selectFilew(event) {
    this.img_c = event.target.files;
    //   this.img_2 = this.img_c[0].name;
    //   this.img_2 = Date.now() + '.jpg';
    console.log(this.img_c[0].name, 'Wfa');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      //   reader.onload = () => { // when file has loaded
      //     const img = new Image();

      //     img.onload = () => {
      //         this.widthCard = img.width;
      //         this.heightLogo = img.height;
      //         console.log(this.heightLogo + '  ' + this.widthLogo);
      //         this.AspectRatioLogo = this.widthLogo / this.heightLogo;
      //         console.log(this.AspectRatioLogo);
      //     };

      //     img.src = reader.result; // This is the data URL
      // };
      reader.onload = e => (this.imageSrcc = reader.result);
      reader.readAsDataURL(file);
    }
  }
}
