import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from '../_guards';
import {
  ChangePasswordComponent,
  LoginComponent,
  ProfileComponent
} from '../_auth';
import { HomeLayoutComponent, LoginLayoutComponent } from '../_layouts';
import {
  DashboardTourComponent,
  TourCreateComponent,
  TourDetailsComponent,
  TourHomeComponent,
  TourReservationComponent,
  TripDetailsComponent
} from '../_verticals/tour';
import {
  CreateManageUserRoleComponent,
  FinancialComponent,
  GlobalSettingComponent,
  GuestListComponent,
  GuestListItemComponent,
  ManageUserComponent,
  GlobalSettingResComponent
} from '../_shared';
import {
  BranchDetailsComponent,
  BranchesRestaurantComponent,
  CreateBranchComponent,
  DashboardRestaurantComponent,
  EventsComponent,
  EventsEditComponent,
  ReservationsRestaurantComponent,
  ReviewsComponent
} from '../_verticals/restaurant';
import {
  DashboardSprotsComponent,
  ListOfCoursesComponent,
  CreateEventComponent,
  MemberShipListComponent,
  AttendenceComponent,
  TrainersComponent,
  NewTrainerComponent,
  TrainerDetailsComponent,
  EventDetailsComponent
} from '../_verticals/sports';
import {
  CECreateEventComponent,
  CEEventDetailsComponent,
  ConcertsCheckInComponent,
  ConcertsDashboardComponent,
  ConcertsEventsComponent,
  ConcertsReservationsComponent,
  ConcertsVenuesComponent,
  ConcertsCreateReservationComponent
} from '../_verticals/concerts';

import { CarsComponent } from '../_verticals/cars/cars.component';
import { SeatsShowComponent } from '../_verticals/seats/seats-show/seats-show.component';

const routes: Routes = [
  { path: 'cars', component: CarsComponent },
  { path: 'seats', component: SeatsShowComponent },

  { path: '', component: LoginComponent },
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [{ path: '', component: LoginComponent }]
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardTourComponent, canActivate: [AuthGuard] },
      {
        path: 'dashboard',
        component: DashboardTourComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'globalSettings',
        component: GlobalSettingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList',
        component: GuestListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList/:id',
        component: GuestListItemComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'financial',
        component: FinancialComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reservations',
        component: TourReservationComponent,
        canActivate: [AuthGuard]
      },
      { path: 'tours', component: TourHomeComponent, canActivate: [AuthGuard] },
      {
        path: 'tripDetails/:id',
        component: TripDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tour/tourCreate',
        component: TourCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tour/tourDetails/:id',
        component: TourDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-users',
        component: ManageUserComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-users/create-role',
        component: CreateManageUserRoleComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'R',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      /* restaurant Children */
      { path: '', component: DashboardTourComponent, canActivate: [AuthGuard] },
      {
        path: 'dashboard',
        component: DashboardRestaurantComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reservations',
        component: ReservationsRestaurantComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches',
        component: BranchesRestaurantComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches/createBranch',
        component: CreateBranchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches/:id',
        component: BranchDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'events',
        component: EventsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'events/:action',
        component: EventsEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'globalSettings',
        component: GlobalSettingResComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList',
        component: GuestListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList/:id',
        component: GuestListItemComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'financial',
        component: FinancialComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'g',
    component: HomeLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      /* restaurant Children */
      {
        path: '',
        component: DashboardSprotsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        component: DashboardSprotsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'member-ship',
        component: MemberShipListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trainers',
        component: TrainersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trainers/create_trainer',
        component: NewTrainerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trainers/trainerdetails/:id',
        component: TrainerDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches',
        component: BranchesRestaurantComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'attendence',
        component: AttendenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'list_of_courses',
        component: ListOfCoursesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'list_of_courses/create_course',
        component: CreateEventComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'list_of_courses/event/:id',
        component: EventDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches/createBranch',
        component: CreateBranchComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'branches/:id',
        component: BranchDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'globalSettings',
        component: GlobalSettingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList',
        component: GuestListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'guestList/:id',
        component: GuestListItemComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'financial',
        component: FinancialComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'concerts',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConcertsDashboardComponent
      },
      {
        path: 'dashboard',
        component: ConcertsDashboardComponent
      },
      {
        path: 'reservations',
        component: ConcertsReservationsComponent
      },
      {
        path: 'reservations/create-reservation',
        component: ConcertsCreateReservationComponent
      },
      {
        path: 'check-in',
        component: ConcertsCheckInComponent
      },
      {
        path: 'venues',
        component: ConcertsVenuesComponent
      },
      {
        path: 'events',
        component: ConcertsEventsComponent
      },
      {
        path: 'events/create-event',
        component: CECreateEventComponent
      },
      {
        path: 'events/:id',
        component: CEEventDetailsComponent
      }
    ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
