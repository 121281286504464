import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToursService } from '../../../_services';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  displayedColumns = [
    'name',
    'city',
    'branch',
    'date',
    'time',
    'reserve',
    'View'
  ];
  dataSource: MatTableDataSource<{}>;
  eventList: any[] = [];

  constructor(private tours: ToursService, private router: Router) { }

  ngOnInit() {
    this.eventList.push({ name: 'mo', city: 'giza', branch: 'd', date: '22', time: '3', reserve: 'can' });
    this.eventTable();
  }
  eventTable() {
    // Matrial Datatable angular
    this.tours.loadEvents().subscribe(data => {
      this.dataSource = new MatTableDataSource(data['event']);
      this.eventList = data['event']
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log(this.dataSource);
    })

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
